<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-12 text-right">
        <button class="btn btn-secondary mr-2" @click="modal_nuevo=true">Agregar revisión</button>
        <button class="btn btn-info">Enviar notificación Pre-Comité</button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <table>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Descripción</th>
              <th>Fecha creación</th>
              <th>Estatus</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="solicitud.revisiones.length == 0">
              <td colspan="5">No hay revisiones</td>
            </tr>
            <template v-else>
            <tr v-for="revision in solicitud.revisiones">
              <td>{{ buscar_nombre(revision.tipo) }}</td>
              <td>{{ revision.descripcion }}</td>
              <td>{{ $moment(revision.created_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
              <td>{{ revision.estatus == 1 ? 'Activo' : 'Resuelto' }}</td>
              <td>
                <img class="mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar revision" title="Editar revision" @click="editar_revision(revision)" />
                <img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar revision" title="Elimitar revision" @click="eliminar_revision(revision)" />
              </td>
            </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <Nuevo v-if="modal_nuevo" :solicitud="solicitud" :revision="revision" @update="actualizar_solicitud" @close="cancelar_revision" />
  </div>
</template>

<script type="text/javascript">
  import Nuevo from './Revision/Nuevo'

  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'
  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    components: {
      Nuevo
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            revisiones: []
          }
        }
      }
    }
    ,data() {
      return {
        modal_nuevo: false
        ,catalogo: {
          opciones: []
        }
        ,revision: {
          id: null
          ,tipo: null
          ,descripcion: null
        }
      }
    }
    ,mounted() {
      this.obtener_catalogos();
    }
    ,methods: {
      async obtener_catalogos() {
        try {
          this.catalogo = await this.$helper.cache_store('formalizacion.catalogos.tipo_revisiones', async () => {
            return (await apiCatalogo.buscar_catalogo('revisiones')).data;
          });
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cancelar_revision() {
        this.modal_nuevo = false;
        this.revision = {
          id: null
          ,tipo: null
          ,descripcion: null
        }
      }
      ,actualizar_solicitud() {
        this.$emit('update');
        this.cancelar_revision();
      }
      ,buscar_nombre(tipo) {
        let nombre;

        this.catalogo.opciones.forEach(opcion => {
          if (opcion.valor == tipo)
            nombre = opcion.nombre;
        })

        return nombre;
      }
      ,editar_revision(revision) {
        if (revision.estatus == 2)
          return this.$helper.showMessage('Error','No puedes editar una revisión ya resuelta','error','alert');

        this.revision = revision;
        this.modal_nuevo = true;
      }
      ,async eliminar_revision(revision) {
        if (revision.estatus == 2)
          return this.$helper.showMessage('Error','No puedes eliminar una revisión ya resuelta','error','alert');

        try {
          let res = (await apiSolicitudes.eliminar_revision(this.solicitud.id, revision.id)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  table {
    width: 100%;

    thead {
      tr {
        background-color: #4C4C4C;
        color: #fff;

        th {
          padding: 5px 10px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: solid 1px #4D4D4D;

        td {
          padding: 5px 10px;

          img {
            width: 20px;
          }
        }
      }

      tr:last-child {
        border-bottom: 0px;
      }
    }
  }
</style>